<template>
  <PageContainer>
    <PageTitle class="w-full lg:w-form-auth-default">
      {{ trans('LABEL:PasswordUpdate') }}
    </PageTitle>
    <t-card variant="auth">
      <div>
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <form
              class="w-full flex flex-col gap-3"
              ref="forgotPasswordForm"
              @submit.prevent="handleFormSubmit"
          >
            <Hint variant="Info">
              {{ trans('TEXT:PasswordResetComment') }}
            </Hint>
            <FieldWrapper>
              <TextField
                  autocomplete
                  name="email"
                  field="email"
                  rules="email|required"
                  v-model="email"
                  :placeholder="trans('LABEL:Email')"
              />
            </FieldWrapper>
            <div class="flex items-center justify-end">
              <Button
                variant="buttonRed"
                type="submit"
                :loading="passwordForgotLoading"
                :disabled="invalid || passwordForgotDisable"
                :text="trans('BUTTON:Send')"
              />
            </div>
          </form>
        </ValidationObserver>
      </div>
    </t-card>
  </PageContainer>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import Button from "@/utils/crud/components/Button";
import TextField from "@/utils/crud/components/field-types/Text";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import Hint from "@/views/content/components/Hint"

export default {
  data () {
    return {
      email: '',
    }
  },
  components: {
    Button, TextField, FieldWrapper, Hint, PageContainer, PageTitle
  },
  computed: {
    ...mapGetters('account', ['passwordForgotLoading', 'passwordForgotDisable'])
  },
  methods: {
    ...mapMutations('account', ['setPasswordForgotDisable']),
    ...mapActions('account', ['forgotPasswordRequest']),
    handleFormSubmit (e) {
      e.preventDefault();

      const { email} = this;
      if (email) {
        if(this.$refs.form.validate()) {
          this.forgotPasswordRequest({ email})
        }
      }
    }
  },
  mounted() {
    this.setPasswordForgotDisable(false);
  }
}
</script>

<style lang="scss">
</style>
